import { Totals } from './interfaces';

export const defaultTotal = {
  hours: 0,
  pay: 0,
};

export const totalAccumulator = (acc: Totals, total: Totals): Totals => ({
    hours: acc.hours + total.hours,
    pay: acc.pay + total.pay,
  });
